<template>
  <ChartCard
    :is-loading="isLoading"
    title="On Your Plate"
    title-tooltip="Your pending tasks"
    :to="{ name: 'root/on-my-plate' }"
    to-label="My Tasks"
  >
    <div class="ma-auto py-16">
      <div class="primary--text text-center big-title">
        {{ dupeSetsToReviewCount }}
      </div>
      <div class="d-flex align-start justify-center">
        <v-icon class="mr-2" color="primary"> $mdi-content-copy </v-icon>
        <span class="description-text">Duplicate sets to be reviewed</span>
      </div>
    </div>
  </ChartCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChartCard from '@/components/common/ChartCard'

export default {
  components: {
    ChartCard,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters('dashboard', ['dupeSetsToReviewCount']),
  },
  async mounted() {
    this.isLoading = true
    await this.getDupeSetsToReviewStats()
    this.isLoading = false
  },
  methods: {
    ...mapActions('dashboard', ['getDupeSetsToReviewStats']),
  },
}
</script>

<style lang="scss" scoped>
.description-text {
  width: 110px;
  color: #706e6b;
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
}

.big-title {
  line-height: 1;
  font-size: 80px;
}
</style>
