<template>
  <v-row>
    <v-col cols="12" md="6" lg="4">
      <SystemPerformanceCard id="systemPerformanceTile" />
    </v-col>
    <v-col cols="12" md="6" lg="4">
      <OnYourPlateCard id="onYourPlateTile" />
    </v-col>
  </v-row>
</template>

<script>
import SystemPerformanceCard from './components/SystemPerformanceCard'
import OnYourPlateCard from './components/OnYourPlateCard'

export default {
  components: {
    SystemPerformanceCard,
    OnYourPlateCard,
  },
}
</script>
