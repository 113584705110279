<template>
  <ChartCard
    :is-loading="isLoading"
    title="System Performance"
    title-tooltip="Duplicate sets performance"
    :to="{ name: 'root/system-performance' }"
    to-label="System Performance"
  >
    <ChartBar v-if="chartData" :chart-data="chartData" />
  </ChartCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChartBar from '@/components/common/ChartBar'
import ChartCard from '@/components/common/ChartCard'

export default {
  components: {
    ChartBar,
    ChartCard,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapGetters('dashboard', ['dupeSetsToReviewStatsByEntity']),
    chartData() {
      if (this.dupeSetsToReviewStatsByEntity.length > 0) {
        const labels = []
        const data = []
        const backgroundColor = []

        for (
          let i = 0, len = this.dupeSetsToReviewStatsByEntity.length;
          i < len;
          i++
        ) {
          const stat = this.dupeSetsToReviewStatsByEntity[i]

          labels.push(stat.label)
          data.push(stat.count)
          backgroundColor.push(stat.color)
        }

        return {
          labels,
          datasets: [
            {
              data,
              maxBarThickness: 60,
              backgroundColor,
            },
          ],
        }
      }
      return null
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getDupeSetsToReviewStats()
    this.isLoading = false
  },
  methods: {
    ...mapActions('dashboard', ['getDupeSetsToReviewStats']),
  },
}
</script>
